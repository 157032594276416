<div class="" [ngClass]="even ? 'rowFlexContainer' : 'rowFlexContainer2'" [ngStyle]="{'background-color': dynamicData?.bgColor}">
    <div class="imageContainer">
        <img class="section_image" [src]="dynamicData?.image.attachment" [alt]="dynamicData?.image.altName">
    </div>
    <div class="content">
        <div class="numberStyle">
            <app-text-element id="description" [data]="dynamicData.number" ></app-text-element>
        </div>
        <div class="contentHeading">
            <app-text-element id="description" [data]="dynamicData.title" ></app-text-element>
        </div>
        <div class="contentDescription">
            <app-text-element id="description" [data]="dynamicData.description" ></app-text-element>
        </div>
    </div>
</div>