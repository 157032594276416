import { Component, OnInit } from '@angular/core';
import { ElementHelper } from '../../elements/elements_helper';

@Component({
  selector: 'app-custom-text-on-image-element',
  templateUrl: './custom-text-on-image-element.component.html',
  styleUrls: ['./custom-text-on-image-element.component.scss']
})
export class CustomTextOnImageElementComponent implements OnInit{
constructor 
(
  private _elementHelper : ElementHelper,
){}
  ngOnInit(): void {}
  data: any =
  {
    "title":this._elementHelper.getTextTitleElement('ARCHITECTURAL PACKAGE CONTRACTOR','blue',''),
    "description":this._elementHelper.getTextDescElement('Test desctiption','blue',''),
    "image":{
      "attachment":'https://cdn.homedit.com/wp-content/uploads/2014/08/city-beach-exterior-house.jpg',
      "altName":"second banner image",
      "lm_type":"attachment",
      "lm_title":"Second Banner Image",
      "attachment_type":"IMAGE"
    },
  }
}
