import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-input-coloured-element',
  templateUrl: './input-coloured-element.component.html',
  styleUrls: ['./input-coloured-element.component.scss']
})
export class InputColouredElementComponent {
  @Input() responseData : any;

  emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

  data : any;

  constructor(){}

  ngOnInit(): void {
    this.data = this.responseData;
  }
}
