import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TemplateService } from 'src/app/services/template.service';
import { BlogServiceService } from '../../blog-service.service';

@Component({
  selector: 'app-blog-listing',
  templateUrl: './blog-listing.component.html',
  styleUrls: ['./blog-listing.component.scss']
})
export class BlogListingComponent {
  @Input() pageData:any={};
  navId:any;
  constructor(
    private templatesService:TemplateService,
    private router:Router,
    private activatedRoute:ActivatedRoute,
    private blogService:BlogServiceService,
    ){
    
  }
  ngOnInit(): void {
    this.getAllBlogs();
    this.pageData.blogSectionTitle1="latest";
    this.pageData.blogSectionTitle2="News";
  }

  blogsData:any=[
    // {
    //   blogHeading:"The road to success is always under construction",
    //   blogCreatedDate:'April 20, 2015'
    // },
    // {
    //   blogHeading:"The road to success is always under construction",
    //   blogCreatedDate:'April 20, 2015'
    // },
    // {
    //   blogHeading:"The road to success is always under construction",
    //   blogCreatedDate:'April 20, 2015'
    // }
  ]

  blogData:any="";
  getAllBlogs(){
    this.blogService.getBlogByBuisnessId(JSON.parse(window.localStorage.getItem('bDetails')||"").id).subscribe({
      next:(value:any)=>{
        //console.log("value",value.data);
        this.blogsData=value.data;
        //console.log("blogsData",this.blogsData);
            
      },
      error:(err)=>{
        //console.log("error",err);
        
      },
    })
  }



  clickCards(cards:any){
    //console.log(cards);
    this.router.navigate(['/view-blog'],{queryParams:{blogId:cards.id}})
    }
}
