<div class="main_section">        
    <div class="title_section">
        <div class="title">
            <app-text-element [data] = "data.title"></app-text-element>
        </div>
        <div class="description">
            <app-text-element [data] = "data.description"></app-text-element>
        </div>
    </div>
    <div class="input_section">
        <div class="name_mail">
            <input type="text" placeholder="Name,surname" class="name">
            <input type="text" placeholder="E-mail" class="mail">
        </div>
        <div class="input_description">
            <input type="text" placeholder="Your Message" class="input-description">
        </div>
        <div class="send_button">
             <app-button-element [data] = "data.button"></app-button-element>
        </div>
    </div>
</div> 