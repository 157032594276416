<div class="test-content" [ngStyle]="{'background-color': dynamicData?.bgColor}">
    <img [src]="dynamicData.image.attachment" [alt]="dynamicData.image.altName" class="text-content-img" >
    <div class="details-container">
        <div class="text">
            <app-text-element id="description" [data]="dynamicData.title" ></app-text-element>
        </div>
        <div class="person_shot_note">
            <app-text-element id="description" [data]="dynamicData.description" ></app-text-element>
        </div>
        <div class="person-details" >
            <span class="person-name">
                <app-text-element id="description" [data]="dynamicData.name" ></app-text-element>
            </span>
            <span class="person-type">
                <app-text-element id="description" [data]="dynamicData.designation" ></app-text-element>
            </span>
        </div>
    </div>
</div>