import { Component, Input, OnInit } from '@angular/core';
import { ElementHelper } from '../../../../elements/elements_helper';

@Component({
  selector: 'app-about-us-herobanner',
  templateUrl: './about-us-herobanner.component.html',
  styleUrls: ['./about-us-herobanner.component.scss']
})
export class AboutUsHerobannerComponent implements OnInit {
  @Input() responseData : any;
  constructor(
    private _elementHelper : ElementHelper
  ){}
  webComponentName : any  = 'about_us_herobanner';
  ngOnInit(): void {
    this.data = this.responseData.styles;
  }
  data : any = {
    "title":this._elementHelper.getTextTitleElement('Service','',''),
    "description":this._elementHelper.getTextDescElement('Sed do eiusmod tempor incididunt ut labore et when the musis dolore magna aliqua.','',''),
    "image":{
      "attachment":"https://www.grssigns.co.uk/wp-content/uploads/web-Header-Background.jpg",
      "altName":"banner image",
      "lm_type":"attachment",
      "lm_title":"Banner Image",
      "attachment_type":"IMAGE"
    }
  }
}
