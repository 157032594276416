<section class="main_section" *ngIf="!pageLoader">
  <div class="page_title_description">
    <div class="page_title">Search Engine Optimization & Sharing</div>
    <div class="description">
      Set how your site looks like on search engines and when shared.
    </div>
  </div> 
  <ng-container *ngIf="viewAccess">
    <div class="meta_card">
      <div class="card_title">Meta Card</div>
      <div class="hr_line"></div>
      <div class="card_bottom_layer">
        <div class="card_left_part">
          <!-- meta title -->
          <div>
            <p class="box_title">Meta Title
              <mat-icon mat-ra ised-button #metaTitleTooltip="matTooltip" class="i-icon"
                matTooltip="{{'The Meta Title is the name of your webpage that shows up in search engine results.A good Meta Title can help attract more visitors by including important keywords and clearly describing the content'}}"
                matTooltipPosition="right" matTooltipClass="infotooltip" (click)="metaTitleTooltip.toggle()"
                style="color: rgb(173, 173, 173); font-size: 18px !important; height: 24px">info</mat-icon>
            </p>
          </div>
          <mat-form-field appearance="outline" class="input_card">
            <input placeholder="Enter meta title" matInput maxlength="60" (keyup)="metaTitleFun()"
              [(ngModel)]="gettingData?.seoData.metaTitle" />
          </mat-form-field>
          <div class="recomended_chars">
            Recommended: 60 characters. You’ve used <span
              style="color: #36B37E;">{{gettingData?.seoData?.metaTitle?.length}}</span>
          </div>

          <!-- meta description -->
          <div>
            <p class="box_title">Meta Description
              <mat-icon mat-ra ised-button #metaDescTooltip="matTooltip" class="i-icon"
                matTooltip="The Meta Description is a short paragraph that appears under your Meta Title in search results. A well-written Meta Description can encourage more clicks by highlighting key information and using appealing language"
                matTooltipPosition="right" matTooltipClass="infotooltip" (click)="metaDescTooltip.toggle()"
                style="color: rgb(173, 173, 173); font-size: 18px !important; height: 24px">info</mat-icon>
            </p>
          </div>
          <textarea id="meta_description" cols="50" rows="4" class="meta_desc" placeholder="Enter meta Description"
            maxlength="140" [(ngModel)]="gettingData?.seoData.metaDescription" (keyup)="metaDescFun()"></textarea>
          <div class="recomended_chars">
            Recommended: 140 characters. You’ve used <span
              style="color: #36B37E;">{{gettingData?.seoData?.metaDescription?.length}}</span>
          </div>

          <div>
            <p class="box_title">Favicon
              <mat-icon mat-ra ised-button #faviconTooltip="matTooltip" class="i-icon"
                matTooltip="A Favicon is a small icon that appears next to your website is title in the browser tab. It is also visible in bookmarks and search results. Adding a Favicon gives your website a more professional look and improves user experience"
                matTooltipPosition="right" matTooltipClass="infotooltip" (click)="faviconTooltip.toggle()"
                style="color: rgb(173, 173, 173); font-size: 18px !important; height: 24px">info</mat-icon>
            </p>
          </div>
          <div class="upload-image" (click)="uploadImage.click()" *ngIf="!favIcon?.url">
            <img src="https://prod-simpo.s3.ap-south-1.amazonaws.com/library-media/354514c1711643199684photo.png" alt=""
              style="height: 40px;">
            <p class="upload-file mb-0">Upload a file</p>
            <p class="mb-0">or select an existing one</p>
            <span style="color: #989DA7;">up to 32MB</span>
            <input type="file" name="myfile" accept="image/x-png,image/gif,image/jpeg,image/jpg" hidden
             (change)="uploadFavIcon($event)" class="browse-btn display_image" id="browse_bg_img" #uploadImage />
          </div>
          <div class="sec" *ngIf="favIcon?.url">
            <img [src]="ds.bypassSecurityTrustUrl(favIcon.url)">
            <div class="replace-delete">
              <div class="repalce-image cursor-pointer" (click)="uploadImage.click()">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" width="24"
                  height="24" class="icon w-4 h-4" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                    d="m4 16 4.58579-4.5858c.78104-.781 2.04741-.781 2.82841 0L16 16m-2-2 1.5858-1.5858c.781-.781 2.0474-.781 2.8284 0L20 14m-6-6h.01M6 20h12c1.1046 0 2-.8954 2-2V6c0-1.10457-.8954-2-2-2H6c-1.10457 0-2 .89543-2 2v12c0 1.1046.89543 2 2 2Z">
                  </path>
                </svg>
                <span>Replace</span>
              </div>
              <input type="file" name="myfile" accept="image/x-png,image/gif,image/jpeg,image/jpg" hidden
             (change)="uploadFavIcon($event)" class="browse-btn display_image" id="browse_bg_img" #uploadImage />
              <div class="delete-image cursor-pointer ">
                <mat-icon (click)="deleteFavIcon()">delete</mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="card_right_part">
          <div class="box_title">Search Engine Result Preview</div>
          <div class="search_engine_box">
            <div class="google_searchbox">
              <div class="google_img">
                <img src="./../../../../../assets/images/common/google.svg" alt="google">
              </div>
              <mat-form-field class="google_search" appearance="outline">
                <input matInput [(ngModel)]="gettingData?.seoData.metaTitle" disabled />
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
            </div>
            <div class="google_result">
              {{domainName}} › home
            </div>
            <div class="meta_title">
              {{gettingData?.seoData?.metaTitle ? gettingData.seoData?.metaTitle : 'Meta Title'}}
            </div>
            <div class="meta_description">
              {{gettingData?.seoData?.metaDescription ? gettingData?.seoData?.metaDescription : 'Meta Description'}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hr_line"></div>
    <div class="update_btn">
      <button (click)="updateSeoTags()">Update</button>
    </div>
  </ng-container>
  <div *ngIf="!viewAccess" class="not-access-text">You don't have view access</div>

</section>
<section class="loader_section" *ngIf="pageLoader">
  <div class="api_loader">
    <app-anime-loader></app-anime-loader>
  </div>
</section>
