<section class="person-card-section">
  <div class="image-container">
    <img [src]="details.image.attachment" alt="person image">
    <div class="image-content">
      <app-text-element [data]="details.description"></app-text-element>
    </div>
  </div>
  <div class="person-info">
    <div class="person-name">
      <app-text-element [data]="details.name"></app-text-element>
    </div>
    <div class="person-designation">
      <app-text-element [data]="details.position"></app-text-element>
    </div>
  </div>
</section>
