import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { DbService } from 'src/app/services/db-service/db.service';
import { TemplateService } from 'src/app/services/template.service';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import Swal from 'sweetalert2';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Observable, Subscription } from 'rxjs';
import { Industry, StorageKeys } from 'src/app/shared-module-files/simpo.constant';
import { environment } from 'src/environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { StaffServiceService } from 'src/app/master-config-components/micro-apps/staff/service/staff-service.service';

enum FormType {
  "SIGN_UP" = "SIGN_UP",
  "LOGIN" = "LOGIN"
}
@Component({
  selector: 'app-signin-signup',
  templateUrl: './signin-signup.component.html',
  styleUrls: ['./signin-signup.component.scss', './mq-signin-signup.component.scss']
})
export class SigninSignupComponent {
  constructor(
    private router: Router,
    private authService: AuthServiceService,
    private templateService: TemplateService,
    private _dbService: DbService,
    private SocialAuthService: SocialAuthService,
    private deviceService: DeviceDetectorService,
    private staffService: StaffServiceService
  ) {
  }
  // businessDetails: any = {
  //   businessName: '',
  //   phoneNumber: '',
  //   email: '',
  //   businessType: '',
  //   city: '',
  //   address: '',
  //   checked: true,
  // };
  updatingObject = {
    id: '',
    websiteUrl: ''
  };
  emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
  businessForm: any = 'loginDetails';
  edit: any;
  loader: boolean = false;
  websiteId: string = '';

  countryCodes: any[] = [];
  selectedCountry: any;

  formType: FormType = FormType.SIGN_UP;
  checkedColor = '#025eb0'

  googleUser: any;
  loggedIn: any

  lowercase: string = "";

  onEmailChange(event) {
    this.signInForm.controls["email"].setValue(event.target.value.toLowerCase());
    this.signupForm.controls["email"].setValue(event.target.value.toLowerCase());
  }

  ngOnInit() {
    let deviceInfo = this.deviceService.getDeviceInfo();
    // console.log(deviceInfo)
    if (this._dbService.getBusinessId() != null) {
      this.router.navigate([`/admin/home`]);
    }

    if (sessionStorage.getItem('remember') === null) {
      this.signInForm.get('rememberMe')?.setValue(false);
    }
    else {
      let remember = sessionStorage.getItem('remember');
      if (remember === 'true') {
        this.signInForm.get('rememberMe')?.setValue(true);
        let mailId = sessionStorage.getItem('eucpviqc')
        if (!mailId) return;
        let pwd = sessionStorage.getItem('fox')
        if (!pwd) return;

        this.signInForm.get('email')?.setValue(atob(mailId));
        this.signInForm.get('password')?.setValue(atob(pwd));
      }
      else {
        this.signInForm.get('rememberMe')?.setValue(false);
      }
    }

    this.websiteId = localStorage.getItem(StorageKeys.WEBSITE_ID) || '';

    const industry = localStorage.getItem(StorageKeys.INDUSTRY_DETAILS);
    this.selectedIndustry = industry ? JSON.parse(industry) : this.selectedIndustry;

    const currentUrl = this.router.url.split('/')[1];;

    if (currentUrl === 'signup') {
      this.formType = FormType.SIGN_UP;
    } else {
      this.formType = FormType.LOGIN;
    }
    if (localStorage.getItem(StorageKeys.GOOGLETOKENID)) {
      this.SocialAuthService.signOut().then((res) => { })
      localStorage.removeItem(StorageKeys.GOOGLETOKENID);
    }
    this.getAllCountries();

    this.triggerGoogleSignin()
  }

  private authSubscription: Subscription | undefined;
  triggerGoogleSignin() {
    this.authSubscription = this.SocialAuthService.authState.subscribe((user) => {
      this.googleUser = user;
      this.loggedIn = (user != null);
      if (this.googleUser) {
        this.login()
      }
    })
  }

  ngOnDestroy() {
    this.authSubscription?.unsubscribe();
  }



  disableDropdown: boolean = false;
  disableCountryCodeDropdown: boolean = false;
  getAllCountries() {
    this.authService.getAllCountries().subscribe(
      (res: any) => {
        this.countryCodes = res.data?.data ?? [];

        const shortCode = localStorage.getItem(StorageKeys.SHORT_CODE);

        let dialCode;
        if (localStorage.getItem(StorageKeys.dialCodeDropdown) === 'true') {
          dialCode = true
        }
        else {
          dialCode = false
        }

        let shortCodeIndex = -1;

        if (shortCode) {
          shortCodeIndex = this.countryCodes.findIndex((c: any) => c.shortCode?.toLowerCase() === shortCode.toLowerCase())
        } else {
          shortCodeIndex = this.countryCodes.findIndex((c: any) => c.shortCode?.toLowerCase() === 'in');
        }

        if (shortCodeIndex > -1) {
          this.selectedCountry = this.countryCodes[shortCodeIndex];
          this.signupForm.get('dialCode')?.setValue(this.countryCodes[shortCodeIndex].shortCode)
          this.disableCountryCodeDropdown = dialCode ? true : false;
        }
      },
      (err) => {
        this.templateService.openSnack(
          `${err.error.message}`,
          'Close',
        )
      }
    )
  }

  selectedIndustry: Industry = {
    name: '',
    industryId: '',
    industryName: '',
    subIndustryId: '',
    subIndustryName: ''
  }

  signIn() {
    this.router.navigate(['/admin/website/preview']);
  }

  //signup
  signupForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    dialCode: new FormControl('', [Validators.required]),
    mobileNumber: new FormControl('', [Validators.required, Validators.maxLength(15)]),
    email: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$')]),
    password: new FormControl('', [Validators.required, Validators.minLength(8), this.validatePassword()]),
  });

  private validatePassword(): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      const oneLetterCapitalPattern = new RegExp(/[A-Z]/g);
      const oneSymbolPattern = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g);
      const oneNumberPattern = new RegExp(/[0-9]/g);

      const errorObj = {
        oneLetterCapital: oneLetterCapitalPattern.test(c.value),
        oneSymbolPattern: oneSymbolPattern.test(c.value),
        oneNumberPattern: oneNumberPattern.test(c.value),
        minimumLength: c.value.length >= 8
      }

      if (!errorObj.minimumLength || !errorObj.oneLetterCapital || !errorObj.oneNumberPattern || !errorObj.oneSymbolPattern) {
        return errorObj;
      }

      return null;
    }
  }

  signupHide = true;
  signinHide = true;

  //signin
  signInForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$')]),
    password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    rememberMe: new FormControl(false)
  });

  redirectToSignup() {
    if (localStorage.getItem(StorageKeys.INDUSTRY_DETAILS) != null) {
      this.router.navigate(['/signup']);
    } else {
      this.router.navigate(['/website-type'])
    }
  }

  redirectToSignIn() {
    this.router.navigate([''])
  }
  redirectFromSignin() {
    window.location.href = 'https://simpo.ai';
    // this.router.navigate([''])

  }

  login() {
    var data;
    this.loader = true;
    if (this.googleUser) {
      data = {
        businessName: localStorage.getItem(StorageKeys.BUSINESS_NAME),
        contact: {
          email: this.googleUser.email
        },
        address: {
          city: localStorage.getItem(StorageKeys.LOCALITY),
          geolocation: {
            latitude: localStorage.getItem(StorageKeys.LATITUDE),
            longitude: localStorage.getItem(StorageKeys.LONGITUDE)
          }
        },
        industryName: this.selectedIndustry.industryName,
        industryId: this.selectedIndustry.industryId,
        subIndustryId: this.selectedIndustry.subIndustryId,
        subIndustryName: this.selectedIndustry.subIndustryName,
        userName: this.googleUser.email.toLowerCase(),
        name: this.googleUser.name,
        templateId: this.websiteId,
        countryId: this.selectedCountry?.id ?? '',
        countryCode: this.selectedCountry?.shortCode ?? '',
        googleToken: this.googleUser ? this.googleUser.idToken : null,
        websiteStatus: localStorage.getItem(StorageKeys.WEBSITE_STATUS),
        affiliatePartnerKey : null
      }
    } else if (this.websiteId) {
      data = {
        businessName: localStorage.getItem(StorageKeys.BUSINESS_NAME),
        address: {
          city: localStorage.getItem(StorageKeys.LOCALITY),
          geolocation: {
            latitude: localStorage.getItem(StorageKeys.LATITUDE),
            longitude: localStorage.getItem(StorageKeys.LONGITUDE)
          }
        },
        industryName: this.selectedIndustry.industryName,
        industryId: this.selectedIndustry.industryId,
        subIndustryId: this.selectedIndustry.subIndustryId,
        subIndustryName: this.selectedIndustry.subIndustryName,
        userName: this.signInForm.value.email.toLowerCase(),
        pwd: this.signInForm.value.password,
        templateId: this.websiteId,
        countryId: this.selectedCountry?.id ?? '',
        countryCode: this.selectedCountry?.shortCode ?? '',
        websiteStatus: localStorage.getItem(StorageKeys.WEBSITE_STATUS)
      }
    } else {
      data = {
        userName: this.signInForm.value.email.toLowerCase(),
        pwd: this.signInForm.value.password,
      }
    }

    if(this.googleUser)
      {
      if(localStorage.getItem("partnerId"))
      {
        data.affiliatePartnerKey = localStorage.getItem("partnerId")
      }
      }

    this.authService.login(data).subscribe(
      (res: any) => {

        if (res.data === 'New Google User Found') {
          localStorage.setItem(StorageKeys.GOOGLETOKENID, this.googleUser.idToken);
          localStorage.setItem("countryCode", this.selectedCountry?.shortCode);
          localStorage.setItem("countryId", this.selectedCountry?.id);
          this.router.navigate(['/website-type']);
          return;
        }

        localStorage.setItem(StorageKeys.STAFF_ID, res.data.id);
        localStorage.setItem(StorageKeys.STAFF_NAME, res.data.name);
        this._dbService.setFname(res.data.name)
        localStorage.setItem(StorageKeys.BUSINESS_NAME, res.data.business[0].name);
        window.localStorage.setItem('business', JSON.stringify(res.data.business));
        localStorage.setItem(StorageKeys.SUBINDUSTRYID, this.selectedIndustry.subIndustryId);

        // localStorage.setItem("countryCode", data.countryCode);
        // localStorage.setItem("countryId", data.countryId);

        // const selectedBusinessName = localStorage.getItem(StorageKeys.BUSINESS_NAME);

        // if (selectedBusinessName) {
        //   this.router.navigate(['v2-generator']);
        // } else
        if (window.localStorage.getItem('EXISTING') === 'YES') {
          localStorage.removeItem("EXISTING");
          res.data.business.forEach(element => {
            if (((element.name).toLowerCase()).split(' ').join('_') === ((data.businessName).toLowerCase()).split(' ').join('_')) {
              this.getBusinessDetailsById(element.id);
              // this.getStaffPermission(res.data.id, element.id)
              this.getCurrencySymbol(element.id);
              this._dbService.setBusinessId(element.id);
            }
            // else{
            //   element.favourite = false;
            // }
          });
          // let a = res.data.business.find(e => e.favourite);
        }
        else {
          if (res.data.business.length === 1) {
            this._dbService.setBusinessId(res.data.business[0].id);
            this.getBusinessDetailsById(res.data.business[0].id);
            // this.getStaffPermission(res.data.id, res.data.business[0].id)
            this.getCurrencySymbol(res.data.business[0].id);
          } else {
            let a = res.data.business.find(e => e.favourite);
            if (this.websiteId) {
              this._dbService.setBusinessId(res.data.business[res.data.business.length - 1].id);
              this.getBusinessDetailsById(res.data.business[res.data.business.length - 1].id);
              // this.getStaffPermission(res.data.id, res.data.business[res.data.business.length - 1].id)
              this.getCurrencySymbol(res.data.business[0].id);
            } else if (a) {
              this.getBusinessDetailsById(a.id);
              // this.getStaffPermission(res.data.id, a.id)
              this.getCurrencySymbol(a.id);
              this._dbService.setBusinessId(a.id);
            } else {
              this.router.navigate(['/list-business']);
            }
          }
        }

        if (this.signInForm.get('rememberMe')?.value) {
          sessionStorage.setItem('eucpviqc', btoa((this.signInForm.value.email).toLowerCase()));
          sessionStorage.setItem('fox', btoa(this.signInForm.value.password));
          sessionStorage.setItem('remember', `${this.signInForm.get('rememberMe')?.value}`);
        }
        else {
          sessionStorage.setItem('remember', `${this.signInForm.get('rememberMe')?.value}`);
          sessionStorage.clear();
        }

      },
      (err) => {
        this.loader = false;
        this.templateService.openSnack(`${err.error.message}`, 'Close')
      }
    );
  }

  getStaffPermission(staffId: any, businessId: any, businessType: any){
    this.staffService.getStaffPermissionByStaffId(staffId, businessId).subscribe((res: any) => {
      localStorage.setItem(StorageKeys.PERMISSIONS, JSON.stringify(res.data));
      if(!businessType || businessType == 'STATIC')
        this.router.navigate(['/admin/home']);
      else
        this.router.navigate(['/admin/products/list-products']);
        this.loader = false;
    }, (err) => {
      this.loader = false;
    })
  }

  createAnAccount() {
    this.loader = true;

    let data : any = {
      businessName: localStorage.getItem(StorageKeys.BUSINESS_NAME),
      contact: {
        mobile: this.signupForm.value.mobileNumber,
        email: this.signupForm.value.email,
        countryCode: this.signupForm.value.dialCode,
        name: this.signupForm.value.name
      },
      address: {
        city: localStorage.getItem(StorageKeys.LOCALITY) ?? "Hyderabad",
        geolocation: {
          latitude: localStorage.getItem(StorageKeys.LATITUDE) ?? "17.360589",
          longitude: localStorage.getItem(StorageKeys.LONGITUDE) ?? "78.474061"
        }
      },
      industryName: this.selectedIndustry.industryName,
      industryId: this.selectedIndustry.industryId,
      subIndustryId: this.selectedIndustry.subIndustryId,
      subIndustryName: this.selectedIndustry.subIndustryName,
      name: this.signupForm.value.name,
      pwd: this.signupForm.value.password,
      templateId: this.websiteId,
      countryId: this.selectedCountry.id,
      countryCode: this.selectedCountry.shortCode,
      websiteStatus: localStorage.getItem(StorageKeys.WEBSITE_STATUS),
      affiliatePartnerKey : ""
    }
    if(localStorage.getItem("partnerId"))
      {
        data.affiliatePartnerKey = localStorage.getItem("partnerId")
      }
    this.authService.createAccount(data).subscribe(
      (res: any) => {
        this._dbService.setBusinessId(res.data.business[0].id)
        this._dbService.setFname(res.data.name);
        window.localStorage.setItem('business', JSON.stringify(res.data.business));
        this.getCurrencySymbol(res.data.business[0].id);
        localStorage.setItem(StorageKeys.STAFF_ID, res.data.id);
        localStorage.setItem(StorageKeys.STAFF_NAME, res.data.name);
        localStorage.setItem(StorageKeys.SUBINDUSTRYID, this.selectedIndustry.subIndustryId);
        // this.getStaffPermission(res.data.id, res.data.business[0].id);

        localStorage.setItem("countryCode", data.countryCode);
        localStorage.setItem("countryId", data.countryId);

        // const selectedBusinessName = localStorage.getItem(StorageKeys.BUSINESS_NAME);

        // if (selectedBusinessName) {
        //   this.router.navigate(['v2-generator']);
        // } else {
        this.getBusinessDetailsById(res.data.business[0].id);
        // }
      },
      (err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.error.message
        });
        this.loader = false;
      }
    )
  }

  getCurrencySymbol(bId: any) {
    this.authService.getCurrencySymbol(bId).subscribe({
      next: (value: any) => {
        this._dbService.setBusinessPrefernce(JSON.stringify(value.data));
      }
    })
  }

  getBusinessDetailsById(bId: any) {
    this.authService.getDetailsByBusinessId(bId).subscribe(
      (res: any) => {
        window.localStorage.setItem('bDetails', JSON.stringify(res.data.business));
        window.localStorage.setItem('type', 'admin');
        this.getStaffPermission(localStorage.getItem(StorageKeys.STAFF_ID), bId, res.data.business.type);
      },
      (err) => {
        this.loader = false;
      }
    )
  }

  goBackToPreviousPage() {
    if (this.websiteId) {
      this.router.navigate([`v3-generator`]);
    } else {
      this.router.navigate(['/']);
    }
  }

  privacyPolicy() {
    // this.router.navigate(['/privacy-policy']);
    window.open(`${environment.redirectingOtherSite}/privacy-policy`, '_blank');
  }
  termsConditions() {
    // this.router.navigate(['/terms-conditions'])
    window.open(`${environment.redirectingOtherSite}/terms-conditions`, '_blank');
  }

  showToolTip: boolean = false;

  hide() {
    this.showToolTip = false;
  }

  forgotPassword() {
    this.templateService.openDialog(
      ForgotPasswordComponent,
      'auto',
      '540px',
      '80vw'
    )
  }
}
