<section class="main_section" *ngIf="websiteStatus != 'DISABLED'" id="main_section">
  <section class="options_section" *ngIf="screenWidth > 1200">
    <div class="edit-icon" (click)="editWebsite(websiteStatus)" *ngIf="editAccess">
      <mat-icon>edit</mat-icon> &nbsp;Edit
    </div>
    <div class="edit-icon" *ngIf="!editAccess" #tooltip="matTooltip" matTooltip="You don't have edit access"
      matTooltipPosition="above" matTooltipClass="infotooltip">
      <mat-icon style="width: 23px; height: 23px; font-size: 23px;">edit</mat-icon> &nbsp;Edit
    </div>
    <div class="domain_publish">
      <div class="domain_url d-flex justify-content-between">
        <div class="domain" *ngIf="websiteStatus === 'PUBLISHED'">{{websiteUrl}} <img
            src="../../../../../assets/images/master/copy.svg" alt="" class="cursor-pointer focus-ring ms-1"
            (click)="copyToClipboard(websiteUrl)"></div>
        <!-- <div class="domain" *ngIf="path != ''">{{websiteUrl}}{{path}} <img
                        src="../../../../../assets/images/master/copy.svg" alt="" class="cursor-pointer focus-ring ms-1"
                        (click)="copyToClipboard(websiteUrl + path)"></div> -->
        <div class="custom_domain" (click)="connectCustomDomain()">Connect custom domain</div>
      </div>
      <div class="published_not"
        [ngClass]="{'success-text': websiteStatus === 'PUBLISHED', 'warning-text': websiteStatus === 'PRIVATE', 'color-red' : websiteStatus === 'NOT_PUBLISHED'}">
        &#x2022; {{websiteStatus?.replaceAll("_", " ") | titlecase}}
      </div>
    </div>
    <div class="preview_monitors" [style.width]="websiteStatus === 'NOT_PUBLISHED' ? '20%' : ''">
      <div class="mobile_desktop">
        <div class="monitor" (click)="changeMonitors('DESKTOP')">
          <img src="./../../assets/images/preview_site/monitor.svg" alt="monitor_screen" *ngIf="viewType !== 'DESKTOP'">
          <img src="./../../assets/images/preview_site/monitor_active.svg" alt="active_monitor_screen"
            *ngIf="viewType === 'DESKTOP'">
        </div>
        <div class="hr_line"></div>
        <div class="mobile" (click)="changeMonitors('TABLET')">
          <img src="./../../assets/images/preview_site/tab.svg" alt="mobile_screen" *ngIf="viewType !== 'TABLET'">
          <img src="./../../assets/images/preview_site/tab_active.svg" class="active_tab_screen" alt="active_mobile_screen"
            *ngIf="viewType === 'TABLET'">
        </div>
        <div class="hr_line"></div>
        <div class="mobile" (click)="changeMonitors('MOBILE')">
          <img src="./../../assets/images/preview_site/mobile.svg" alt="mobile_screen" *ngIf="viewType !== 'MOBILE'">
          <img src="./../../assets/images/preview_site/mobile_active.svg" alt="active_mobile_screen"
            *ngIf="viewType === 'MOBILE'">
        </div>
      </div>
      <div class="preview_icon" (click)="previewCurrentWebsite()" *ngIf="websiteStatus === 'PUBLISHED'">
        <mat-icon style="width: 23px; height: 23px; font-size: 23px;">remove_red_eye</mat-icon>
      </div>
      <div class="signUp-btn publish-button" *ngIf=" websiteStatus === 'NOT_PUBLISHED'">
        <button (click)="changeStatus()" *ngIf="!loader">Publish</button>
        <button *ngIf="loader"><i class="fa-solid fa-loader font-style">Publishing</i></button>
      </div>
    </div>
  </section>

  <section class="options_section_mobile" *ngIf="screenWidth < 475">
    <div class="domain_publish">
      <div class="domain_url" *ngIf="websiteStatus === 'PUBLISHED'">
        <div class="domain">{{this.websiteUrl}}</div>
      </div>
      <div class="published_not" [ngClass]="{'published': this.websiteStatus === 'PUBLISHED'}">
        &#x2022; {{websiteStatus | titlecase}}
      </div>
    </div>
  </section>
  <section class="options_section" *ngIf="screenWidth > 475 && screenWidth < 1200" style="height: calc(15vh - 0px);">
   <div class="tab-container">
    <div class="domain_publish">
      <div class="domain_url d-flex justify-content-between">
        <div class="domain" *ngIf="websiteStatus === 'PUBLISHED'">{{websiteUrl}} <img
            src="../../../../../assets/images/master/copy.svg" alt="" class="cursor-pointer focus-ring ms-1"
            (click)="copyToClipboard(websiteUrl)"></div>
        <!-- <div class="domain" *ngIf="path != ''">{{websiteUrl}}{{path}} <img
                        src="../../../../../assets/images/master/copy.svg" alt="" class="cursor-pointer focus-ring ms-1"
                        (click)="copyToClipboard(websiteUrl + path)"></div> -->
        </div>
      <div class="published_not"
        [ngClass]="{'success-text': websiteStatus === 'PUBLISHED', 'warning-text': websiteStatus === 'PRIVATE', 'color-red' : websiteStatus === 'NOT_PUBLISHED'}">
        &#x2022; {{websiteStatus?.replaceAll("_", " ") | titlecase}}
      </div>
    </div>
    <div class="down-panel">
      <div class="edit-icon" (click)="editWebsite(websiteStatus)" *ngIf="editAccess">
        <mat-icon>edit</mat-icon> &nbsp;Edit
      </div>
      <div class="custom_domain" (click)="connectCustomDomain()"> &#x2022; Connect custom domain</div>
      <div class="preview_monitors" [style.width]="websiteStatus === 'NOT_PUBLISHED' ? '20%' : ''">
        <div class="mobile_desktop">
          <div class="hr_line"></div>
          <div class="mobile" (click)="changeMonitors('TABLET')">
            <img src="./../../assets/images/preview_site/tab.svg" alt="mobile_screen" *ngIf="viewType !== 'TABLET'">
            <img src="./../../assets/images/preview_site/tab_active.svg" class="active_tab_screen" alt="active_mobile_screen"
              *ngIf="viewType === 'TABLET'">
          </div>
          <div class="hr_line"></div>
          <div class="mobile" (click)="changeMonitors('MOBILE')">
            <img src="./../../assets/images/preview_site/mobile.svg" alt="mobile_screen" *ngIf="viewType !== 'MOBILE'">
            <img src="./../../assets/images/preview_site/mobile_active.svg" alt="active_mobile_screen"
              *ngIf="viewType === 'MOBILE'">
          </div>
        </div>
        <div class="preview_icon" (click)="previewCurrentWebsite()" *ngIf="websiteStatus === 'PUBLISHED'">
          <mat-icon style="width: 23px; height: 23px; font-size: 23px;">remove_red_eye</mat-icon>
        </div>
        <div class="signUp-btn publish-button" *ngIf=" websiteStatus === 'NOT_PUBLISHED'">
          <button (click)="changeStatus()" *ngIf="!loader">Publish</button>
          <button *ngIf="loader"><i class="fa-solid fa-loader font-style">Publishing</i></button>
        </div>
      </div>
    </div>
  </div>
</section>



  <!-- <section class="website_preview_section" *ngIf="monitorScreen">
        <iframe [src]="ds.bypassSecurityTrustResourceUrl(businessDetails?.websiteUrl)" frameborder="0" style="width:100%;height:100%"></iframe>
    </section> -->
  <!-- <section class="mobile_preview_section" *ngIf="mobileScreen" id="mobileScreen"> -->
  <section class="h-100 w-100 position-relative d-flex justify-content-center">
    <div [ngClass]="{
                'position-absolute': viewType === 'MOBILE' || viewType === 'TABLET',
                'd-flex': viewType === 'MOBILE' || viewType === 'TABLET',
                'justify-content-center': viewType === 'MOBILE' || viewType === 'TABLET',
                'w-100': viewType === 'MOBILE' || viewType === 'TABLET',
                'prview_section': viewType === 'MOBILE' || viewType === 'TABLET',
                'mobile_preview_section': viewType === 'MOBILE',
                'tab_preview_section': viewType === 'TABLET',
                'desktop_view': viewType === 'DESKTOP'
                }">
      <iframe [src]="safeUrl" height="100%" width="100%" (load)="onIframeLoad()"></iframe>
    </div>
  </section>
  <!-- <div [innerHTML]="ds.bypassSecurityTrustHtml(website)"></div> -->
  <!-- </section> -->
  <section class="loader_section" *ngIf="gettingWebsiteDataLoader">
    <app-anime-loader></app-anime-loader>
  </section>
</section>

<section class="main_section_disabled" *ngIf="websiteStatus === 'DISABLED'">
  Your website in DISABLED state
</section>
