<div class="card-section" [ngStyle]="{'background-color': dynamicData?.bgColor}">
    <div class="image-service">
        <img [src]="dynamicData.image.attachment" [alt]="dynamicData.image.altName" loading="lazy">
    </div>
    <div class="card-contentsection p-12">
        <div class="card-text-title" style="margin-top: 10px;">
            <app-text-element id="title" [data]="dynamicData.title"></app-text-element>
        </div>
        <div class="description" style="margin-top: 12px;margin-bottom: 10px;">
            <app-text-element id="description" [data]="dynamicData.description" ></app-text-element>
        </div>
    </div>
</div>