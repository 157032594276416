import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dropdown-element',
  templateUrl: './dropdown-element.component.html',
  styleUrls: ['./dropdown-element.component.scss']
})
export class DropdownElementComponent implements OnInit{

  @Input() responseData : any;

  data : any

  constructor(){

  }

  ngOnInit(): void {
    // console.log(this.responseData);
    this.data = this.responseData;
    if(this.data.options.length != 0){
      this.data.options = this.data.options.split(",")
    }
  }

  dropdownValues : any = [
    'Item 1',
    'Item 2',
    'Item 3',
    'Item 4'
  ]

  optionValue : any  = '';

}
