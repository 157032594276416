import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-text-process-card',
  templateUrl: './simple-text-process-card.component.html',
  styleUrls: ['./simple-text-process-card.component.scss']
})
export class SimpleTextProcessCardComponent implements OnInit{

  @Input() dynamicData : any;

  constructor(){}

  ngOnInit(): void {
  }

}
