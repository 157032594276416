<div class="main-section" [ngStyle]="{'background-color': dynamicData?.bgColor}">
    <div class="left">
        <div class="quote-details">
        <img src="./../../../../../../../../assets/images/testimonials/quote-left.svg" class="quote-img">
        <div class="main-heading">
            <app-text-element id="description" [data]="dynamicData.title" ></app-text-element>
        </div>
    </div>
        <div class="heading">
            <app-text-element id="description" [data]="dynamicData.itemTitle" ></app-text-element>
        </div>
        <div  class="subtext">
            <app-text-element id="description" [data]="dynamicData.description" ></app-text-element>
        </div>
        <!-- <div [ngStyle]="link.style?link.style:{}">{{link?.value}}</div> -->
    </div>
    <div class="right">
        <img [src]="dynamicData.image.attachment" [alt]="dynamicData.image.altName" class="person-img">
        <div class="person-details">
            <div>
            <div class="person-name">
                <app-text-element id="description" [data]="dynamicData.name" ></app-text-element>
            </div>
            <div class="person-type">
                <app-text-element id="description" [data]="dynamicData.designation" ></app-text-element>
            </div>
            </div>
        </div>
    </div>
</div>