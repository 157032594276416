import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-verticle-card',
  templateUrl: './verticle-card.component.html',
  styleUrls: ['./verticle-card.component.scss']
})
export class VerticleCardComponent implements OnInit{

  @Input() dynamicData : any

  constructor(

  ){}

  ngOnInit(): void {

  }

}
