import { Component, HostListener, Input } from '@angular/core';
import { ElementHelper } from '../elements_helper';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';

@Component({
  selector: 'app-banner-content-middle',
  templateUrl: './banner-content-middle.component.html',
  styleUrls: ['./banner-content-middle.component.scss']
})
export class BannerContentMiddleComponent {

  // @Input() details: any;
  constructor(
    private _elementHelper: ElementHelper,
    private _eventHelper : EventEmmiterService
  ) {
    this.getScreenSize()
  }

  screenWidth: any;
  @HostListener("window: resize", ["$event"])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  details: any = {
    "heading": this._elementHelper.getTextItemTitleElementWithFontsize("Our Team", "#8800ff", '', '18px', '', '400'),
    "title": this._elementHelper.getTextTitleElement('The Team Behind Nollie', '#19154e', ''),
    "description": this._elementHelper.getTextDescElement(
      'We Are A Team Of Passionate Web Development Professionals Dedicated To Crafting Beautiful And Functional Websites For Businesses And Individuals. Our Goal Is To Help Our Clients Create An Online Presence That Stands Out And Drives Growth And Success. <br><br> We work with clients from a wide range of industries, including e-commerce, education, healthcare, and more. Our services are tailored to meet the specific needs and objectives of each client.',
      'black',
      ''
    ),
    "image": {
      "attachment": 'https://dev-beeos.s3.amazonaws.com/prod-images/709129c1680923802880ay%20%284%29.jpg',
      "altName": "banner image",
      "lm_type": "attachment",
      "lm_title": "Banner Image",
      "attachment_type": "IMAGE"
    }
  }
}
