import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TemplateService } from 'src/app/services/template.service';
import { StepsToFollowComponent } from '../steps-to-follow/steps-to-follow.component';
import { WebisiteService } from '../../webisite.service';

@Component({
  selector: 'app-connect-existing-domain',
  templateUrl: './connect-existing-domain.component.html',
  styleUrls: ['./connect-existing-domain.component.scss']
})
export class ConnectExistingDomainComponent implements OnInit{

  constructor(
    private templateService : TemplateService,
    public dialogRef: MatDialogRef<ConnectExistingDomainComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private _websiteService : WebisiteService,
  ){
    this.domainData = data;
  }

  businessDetails : any ;
  domainData:any;

  ngOnInit(): void {
    let bDetails = window.localStorage.getItem('bDetails');
    if(bDetails){
      this.businessDetails = JSON.parse(bDetails);
    }
    this.linkingDomain = this.domainData.dnsDomainUrl;
  }

  close(){
    this.dialogRef.close();
  }
  linkingDomain :any = '';
  linkDomain(){
    // this.dialogRef.close();
    // let popup = this.templateService.openDialog(
    //   StepsToFollowComponent,
    //   'auto',
    //   '540px',
    //   '80vw'
    // );
    let data = {
      businessId : this.businessDetails.id,
      domainUrl : 'https://'+this.linkingDomain
    }
    this._websiteService.linkExistingDomain(data).subscribe(
      (res:any) => {
        console.log(res.data);
      },
      (err) => {
        //console.log(err.error);
      }
    )
  }

}
