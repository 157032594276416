import { Component, Input } from '@angular/core';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';

@Component({
  selector: 'app-hover-button-element',
  templateUrl: './hover-button-element.component.html',
  styleUrls: ['./hover-button-element.component.scss']
})
export class HoverButtonElementComponent {
  constructor(
    private _eventHelper : EventEmmiterService
  ){

  }
  @Input() data:any;
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    if(this.data.hasOwnProperty('status')){

    }else{
      this.data.status = true
    }
  }

  clickFunction() {
    this._eventHelper.buttonCick.emit({buttonData:this.data});
  }

}
