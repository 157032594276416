<div class="total_container">
    <div class="main_section">
        <div class="header_title">
            <div class="main_title">
                <app-text-element [data]="data.title"></app-text-element>
            </div>
            <div class="main_description">
                <app-text-element [data]="data.description"></app-text-element>
            </div>
        </div>
        <div class="card_section">
            <ng-container *ngFor = "let item of itemData">
                <div class="terms_conditions">
                    <div class="item_title">
                        <app-text-element [data]="item.title"></app-text-element>
                    </div>
                    <div class="item_description">
                        <app-text-element [data]="item.description"></app-text-element>                    
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="show_btns" *ngIf="all">
        <div class="full_btns">
            <div class="full-edit-section" (click)="editSection()">
                <div class="edit image">
                    <img src="./../../../assets/images/editor/edit_blue.svg" alt="buleditIcon">
                </div>
                <div class="editsection-text">
                    Edit Section
                </div>
            </div>
            <div class="full-edit-section">
                <div class="edit image">
                    <img src="./../../../../../../../../../assets/images/editor/plus-icon.svg"
                        alt="regenrate">
                </div>
                <div class="regenerate-text">
                    Add Section
                </div>
            </div>
        </div>
    </div>
</div>