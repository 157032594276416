<div class="body-styles" [ngStyle]="{'background-color': dynamicData?.bgColor}">
    <div class="left_container">
        <!-- <div [ngStyle]="data[1].style ? data[1].style:{}" class="top_section">
            <div class="top_image">
                <img src="{{data[0].imgUrl}}" alt="">
            </div>
            <div>{{heading.value}}</div>
        </div> -->
        <div class="top_section_text">
            <app-text-element id="description" [data]="dynamicData.title" ></app-text-element>
        </div>
        <div class="short_note">
            <app-text-element id="description" [data]="dynamicData.description" ></app-text-element>
        </div>
    </div>
    <div class="right_container">
        <div class="person-details">
            <div>
                <img [src]="dynamicData?.image.attachment" [alt]="dynamicData?.image.altName" class="person-img">
            </div>
            <div class="person-name">
                <app-text-element id="description" [data]="dynamicData.name" ></app-text-element>
            </div>
            <div class="person-type">
                <app-text-element id="description" [data]="dynamicData.designation" ></app-text-element>
            </div>
        </div>
    </div>
</div>
