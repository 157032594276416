<div class="main_section">
    <div class="card_section">
        <ng-container *ngFor = "let item of itemData">
            <div class="card">
                <div class="image_title">
                    <img class="card_image" [src]="item.image.attachment">
                    <div class="card_title">
                        <app-text-element [data] = "item.title"></app-text-element>
                    </div>
                </div>
                <div class="card_description">
                    <app-text-element [data]="item.description"></app-text-element>
                </div>
            </div>
        </ng-container>
    </div>
</div>