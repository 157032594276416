<div class="main_section">
    <div style="background: url('{{data.image.attachment}}');background-repeat: no-repeat;background-size: 100% 100%;height: 100vh;width: 100%;background-attachment: fixed;" class="title_section">
        
        <div class="title_section">
            <div class="title">
                <app-text-element [data] = "data.title"></app-text-element>
            </div>
            <div class="description">
                <app-text-element [data] = "data.description"></app-text-element>
            </div>
        </div>
    </div>
</div> 