import { Component, Input, OnInit } from '@angular/core';
import { ElementHelper } from '../../elements/elements_helper';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';

@Component({
  selector: 'app-terms-privacy-card',
  templateUrl: './terms-privacy-card.component.html',
  styleUrls: ['./terms-privacy-card.component.scss']
})
export class TermsPrivacyCardComponent implements OnInit{
  @Input() responseData :any
  @Input() all : any;
  constructor(
    private _elementHelper : ElementHelper,
    private _eventHelper : EventEmmiterService,
  ){}
  ngOnInit(): void {
    this.data = this.responseData.styles;
    this.itemData = this.responseData.itemStyles;
  }
  data: any =
  {
    "title":this._elementHelper.getTextTitleElement('Terms and Conditions','',''),
    "description":this._elementHelper.getTextDescElement('','',''),
  }
  itemData: any = 
  [
    {
      "title":this._elementHelper.getTextItemTitleElement('How we use the website information',' ',''),
      "description":this._elementHelper.getTextDescElement('We use the Website Information to provide, improve, maintain and evaluate the Service, including as follows:<br>To understand or analyze how you use the Service and how we might improve or change them.<br>To verify your identity and establish your account.<br>To send you updates, emails, newsletters and other information that may be of interest to you.<br>To send ads to you when you are on the Service or visit other websites (or other media channels and applications); create<br>tailor, deliver and study the effectiveness of advertising and promotional material both on and off of the Service; and to perform other analytics','',''),
    },
    {
      "title":this._elementHelper.getTextItemTitleElement('Information in business profiles collected',' ',''),
      "description":this._elementHelper.getTextDescElement('{Company Name} collects from Business Profiles: Name, Email Addresses, Phones, Social profiles (id, url, name, job title, company, location, skills …).<br>We collect information from the following third-party sources:third parties who license, sell or otherwise provide data they have collected; or <br> (ii) information from publicly available sources, such as via the Internet and social networks, including through public. For example, we use open web services and APIs to complete and update the information we have about Contacts and enrich it (e.g. by adding links to Contacts verified social network profiles), in order to allow our Users and vendors to find relevant Business Profiles.','',''),
    },
    {
      "title":this._elementHelper.getTextItemTitleElement('How we share your Information',' ',''),
      "description":this._elementHelper.getTextDescElement('We share Business Profiles with our users, trusted vendors, and business partners. Specifically, we may disclose to them (or permit their access to) Business Profiles retained in the {Company Name} B2B Database for the purposes of ensuring that their prospective or existing Business Profiles are authentic, current, and up to date.<br>At any time, you may decide to opt-out from enabling us to disclose or allow access to your Business Profile information stored on the {Company Name} B2B Database by filling in your relevant details here.','',''),
    },
    {
      "title":this._elementHelper.getTextItemTitleElement('Children’s privacy',' ',''),
      "description":this._elementHelper.getTextDescElement('{Company Name} does not knowingly collect information from children under the age of 16. If you are under the age of 16, please do not submit any personal data through the Platform. We encourage parents and legal guardians to monitor their children s Internet usage and to help enforce our Privacy Policy by instructing their children never to provide personal data on our Platform without their permission.<br>If you have reason to believe that a child under the age of 16 has provided personal data to {Company Name} through the Platform, please contact privacy@xyz.com and we will endeavor to delete that information from our databases.','',''),
    }
  ];

  editSection(){
    this._eventHelper.editSection.emit({data:this.responseData});
  }
}