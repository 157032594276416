import { Component, Input, OnInit } from '@angular/core';
import { ElementHelper } from '../../../../elements/elements_helper';

@Component({
  selector: 'app-service-section-contact',
  templateUrl: './service-section-contact.component.html',
  styleUrls: ['./service-section-contact.component.scss']
})
export class ServiceSectionContactComponent implements OnInit{
  @Input() responseData : any;
  constructor(
    private _elementHelper : ElementHelper
  ){}
  webComponentName : any  = 'service_section_contact';
  ngOnInit(): void {
   this.data=this.responseData.styles;
  }
data : any = 
{
  "welcometext":this._elementHelper.getTextItemTitleElement('WE ARE HERE FOR YOU','white',''),
  "title":this._elementHelper.getTextTitleElement('Let’s Get Back to Work','white',''),
  "description":this._elementHelper.getTextDescElement('Gom ad minim veniam, quis nostrud exercitation ullamco labor is tetur adip isicing elit, sed do eiusmod','white',''),
  "button":this._elementHelper.getButtonOutlineRoundedElement('Contact Me','white','white',''),
}
}
