import { SocialAuthService } from '@abacritt/angularx-social-login';
import { DOCUMENT } from '@angular/common';
import { Component, HostListener, OnInit, Renderer2,Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DbService } from 'src/app/services/db-service/db.service';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private authService: SocialAuthService,
    private router : Router,
    private _dbService : DbService,
    private templateService:TemplateService,
    private _renderer: Renderer2, 
    @Inject(DOCUMENT) private _document :any,
  ) {
    this.getScreenSize();
    // window.localStorage.clear();
  }

  scrHeight : any;
  scrWidth : any;
  script = this._renderer.createElement('script');
  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // //console.log(this.scrHeight, this.scrWidth);
  }

  ngOnInit(): void {
    if(this._dbService.getBusinessId() != null){
      this.router.navigate([`/admin/home`]);
    }
    else{
      window.localStorage.clear();
    }
    
    this.authService.signOut().then((res) => {})
  }

  go(){
    // this.router.navigate(['/introduction'])
    this.router.navigate([`/business-type`]);
  }

}
